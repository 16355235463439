import React from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'
import { SiteContextProvider } from './context/site-context'

import { createBrowserHistory } from 'history'

import ScrollToTop from 'components/atoms/ScrollToTop'

import App from './App'

import './index.scss'

const history = createBrowserHistory()
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]
if (path) {
  history.replace(path)
}

const root = createRoot(document.getElementById('root'))
root.render(
  <SiteContextProvider>
    <BrowserRouter baseUrl={process.env.PUBLIC_URL}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </SiteContextProvider>
)
