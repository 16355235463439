import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'

const BING_PARAMS = [
  'semb_adgroupid',
  'semb_adposition',
  'semb_campaignid',
  'semb_clid',
  'semb_creative',
  'semb_device',
  'semb_devicemodel',
  'semb_feeditemid',
  'semb_keyword',
  'semb_loc_interest_ms',
  'semb_loc_physical_ms',
  'semb_matchtype',
  'semb_network',
  'semb_placement',
  'semb_target',
  'semb_targetid',
]
const GOOGLE_PARAMS = [
  'sem_adgroupid',
  'sem_adposition',
  'sem_campaignid',
  'sem_clid',
  'sem_creative',
  'sem_device',
  'sem_devicemodel',
  'sem_feeditemid',
  'sem_keyword',
  'sem_loc_interest_ms',
  'sem_loc_physical_ms',
  'sem_matchtype',
  'sem_network',
  'sem_placement',
  'sem_target',
  'sem_targetid',
]
const STATE_ABBREVIATIONS = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]

const buildObjString = obj => {
  let str = []
  Object.keys(obj).forEach( key => {
    str.push(`${key}:"${obj[key]}"`)
  })
  return `{${str.join(' ')}}`
}

const calcOperatingHours = (rawHours = []) => {
  // Get the current day and time.
  const now = new Date()
  const today = now.getDay()
  const hour = now.getHours()
  const minute = now.getMinutes()

  const time = hour * 60 + minute

  // See if the passed in hours contains a day matching the current day.
  const operatingHours = rawHours.filter( day => day.weekday === today )

  let isOpen = false
  if(operatingHours.length > 0 && (operatingHours[0].status === 'H24' || (convertRawTime(operatingHours[0].start1) <= time && convertRawTime(operatingHours[0].end1) >= time))) {
    isOpen = true
  }

  return {
    isOpen: isOpen,
  }
}

const convertRawTime = (rawTime = '') => {
  if(rawTime === '') {
    return null
  }

  let hour = parseInt(rawTime.split(':')[0])
  let minute = parseInt(rawTime.split(':')[1].substr(0, 2))
  let partOfDay = rawTime.split(':')[1].substr(2, 2)

  if(partOfDay.toUpperCase() === 'PM') {
    hour += 12
  }

  return hour * 60 + minute
}

const createMarkup = rawStr => {
  return {
    __html: rawStr
  }
}

const formatPhoneNumber = str => {
  const cleaned = ('' + str).replace(/\D/g, '').substr(-10)
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if(match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return
}

const getAboutUs = (additionalDetails = []) => {
  const aboutUs = additionalDetails.filter( detail => detail.type === 'About Us' && detail.value?.length > 0 )
  return aboutUs.map( detail => <div key={detail.type}>
    {detail.value && <p dangerouslySetInnerHTML={{ __html: detail.value.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></p>}
  </div>)
}

const getAddress = (address = {}) => {
  // Sigh...
  if(address.street1 && address.street1 === 'null') { delete address.street1 }
  if(address.street2 && address.street2 === 'null') { delete address.street2 }
  if(address.city && address.city === 'null') { delete address.city }
  if(address.stateOrProvince && address.stateOrProvince === 'null') { delete address.stateOrProvince }

  return address.street1 || address.city || address.stateOrProvince ?
    `${address.street1 || ''} ${address.street2 || ''} ${address.city || ''}${address.city && address.stateOrProvince ? ',' : ''} ${address.stateOrProvince || ''} ${address.postalCode || ''}` :
    ''
}

const getBingParams = () => {
  const payload = {}
  let qs_params = {}

  try {
    qs_params = JSON.parse(localStorage.getItem('qs_params')) || {}
  } catch (e) {}

  BING_PARAMS.map( param => {
    const value = qs_params[param]
    if(value){
      payload[param] = value
    }
  })
  return payload
}

const getBullets = (bullets = []) => {
  return <ul className="bullets-container">
    {bullets.map( bullet => bullet.trim().length ? <li key={bullet} dangerouslySetInnerHTML={{ __html: bullet.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></li> : null )}
  </ul>
}

const getCategoryIds = site => {
  return site.categoryIds ? `[${site.categoryIds.join(', ')}]` : `[${site.categoryId}]`
}

const getGeoFromLocalStorage = () => {
  let geoInfo = null
  try {
    geoInfo = JSON.parse(localStorage.getItem('geoInfo'))
  } catch (e) { }
  return geoInfo
}

const getGoogleParams = () => {
  const payload = {}
  let qs_params = {}

  try {
    qs_params = JSON.parse(localStorage.getItem('qs_params')) || {}
  } catch (e) {}

  GOOGLE_PARAMS.map( param => {
    const value = qs_params[param]
    if(value){
      payload[param] = value
    }
  })
  return payload
}

const getLocationFromZip = async (zip, callback = () => {}, errorHandler = () => {}) => {
  fetch( `https://secure.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID="100ELOCA1113"><ZipCode ID= "0"><Zip5>${zip}</Zip5></ZipCode></CityStateLookupRequest>`)
    .then( response => response.text() )
    .then(str => (new window.DOMParser()).parseFromString(str, 'text/xml'))
    .then( xml => {
      callback({
        city: xml.getElementsByTagName('City')[0]?.childNodes[0]?.nodeValue,
        region: xml.getElementsByTagName('State')[0]?.childNodes[0]?.nodeValue,
        zip_code: xml.getElementsByTagName('Zip5')[0]?.childNodes[0]?.nodeValue,
      })
    })
}

const getPartnerCampaignId = (contextPartnerIds = [], slug) => {
  return slug ? '40564' : new URLSearchParams(window.location.search).get('partner_campaign_id') || (contextPartnerIds.length ? contextPartnerIds[0] : '46627')
}

const getProfileUrl = ( site = {}, listing = {} ) => {
  const qs = getQS().trim().length > 0 ? `?${getQS()}` : ''
  return `${window.location.origin}/${site.path ? `${site.path}/` : ''}profile/${listing.id}${qs}`
}

const getQS = () => {
  const qs = new URLSearchParams(window.location.search)
  qs.delete('zip')
  qs.delete('term')
  qs.delete('where')
  return qs.toString().trim().length ? `&${qs.toString()}` : ''
}

const getServices = services => {
  const _services = services.reduce( (obj, service) => {
    const cleaned = service.trim().split(' - ')
    if(!obj[cleaned[0]]) {
      obj[cleaned[0]] = []
    }
    if(cleaned[1]){
      obj[cleaned[0]].push(cleaned[1])
    }
    return obj
  }, [])

  return sortBy(Object.keys(_services)).map( key => <div key={key}>
    <ul>
      <li className="headline">{key}</li>
      {sortBy(_services[key]).map( item => <li key={item}><span>{item}</span></li>)}
    </ul>
  </div>)
}

const getSessionId = () => {
  if(!sessionStorage['session_id']){
    const crypto = window.crypto || window.msCrypto
    sessionStorage.setItem('session_id', new Date().getTime() + '-' + crypto.getRandomValues(new Uint8Array(8)).join(''));
  }
  return sessionStorage['session_id'];
}

const getSpecialDiscount = (additionalDetails = []) => {
  const specialDiscount = additionalDetails.filter( detail => detail.type === 'Special Discounts' && detail.value?.length > 0 )
  return specialDiscount.map( detail => <div key={detail.type}>
    {detail.value && <p dangerouslySetInnerHTML={{ __html: detail.value.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></p>}
  </div>)
}

const hasDetails = result => {
  return (hasServices(result) || result.structuredBusinessHours?.length > 0)
}

const hasServices = result => {
  return result.services && result.services.length > 0
}

const hasTags = result => {
  return result.tags && result.tags.filter( tag => !tag.primary ).length > 0
}

const isValidState = (state = '') => {
  return STATE_ABBREVIATIONS.indexOf(state.toUpperCase()) > -1
}

const isWebpSupported = () => {
  let support

  if (typeof support !== 'undefined') {
    return support
  }

  const elem = typeof document === 'object' ? document.createElement('canvas') : {}
  support = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0

  return support
}

const parseXml = (xml, tag) => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(xml, 'text/xml')

  try {
    return xmlDoc.getElementsByTagName('City')[0].childNodes[0].nodeValue.toLowerCase()
  }
  catch (e) {
    return 'Your area'
  }
}

const prettifyCity = ( city = 'Your Area') => {
  return city.split(/[\s-]/)
    .map( section => section.charAt(0).toUpperCase() + section.slice(1).toLowerCase() )
    .join(' ')
}

const renderServices = (serviceGroups = []) => {
  let services = []

  return sortBy(serviceGroups, ['name']).map( serviceGroup => serviceGroup.name ).join(', ')
}

const setCategoryData = data => {
  // Update the DOM (for tracking).
  document.getElementById('root').setAttribute('data-category-type', data.category_type)
  document.getElementById('root').setAttribute('data-parent-category', data.parent_category_name)
  document.getElementById('root').setAttribute('data-parent-category-id', data.parent_category_id)
  document.getElementById('root').setAttribute('data-service-category-id', data.category_id)
  document.getElementById('root').setAttribute('data-service-category-name', data.category_name)

  // Update localStorage.
  try {
    localStorage.setItem('category', JSON.stringify(data))
  } catch (e) {
    console.debug('localStorage not available')
  }
}

const updateLocalStorage = param => {
  try {
    console.debug('updateLocalStorage triggered')

    let params = {}

    // Check for a qs.
    let qs = {}
    new URLSearchParams(window.location.search).forEach( (value, key) => qs[key] = value )

    // Clean up localStorage.
    const savedParams = localStorage.getItem('qs_params')
    try {
      params = JSON.parse(savedParams)
      const now = new Date().getTime()
      if(params.ttl && params.ttl < now) {
        localStorage.removeItem('qs_params')
        console.debug('removed qs_params from localStorage')
      }
    } catch (e) { }

    // Clean up params (need to remove ttl if it exists in order to deep compare against qs)
    if(params && params.ttl) {
      delete params.ttl
    }

    // Clean up qs (remove zip)
    if(qs && qs.zip){
      delete qs.zip
    }

    if(Object.keys(qs).length && !isEqual(qs, params)){
      console.debug('updating qs_params in localStorage')
      localStorage.setItem('qs_params', JSON.stringify({
        ...qs,
        ttl: new Date().getTime() + 6.048e+8 // add 1 week in milliseconds
      }))
    } else {
      console.debug('no change to qs_params in localStorage')
    }
  }
  catch (e) {
    console.debug('localStorage not available')
  }
}

export {
  buildObjString,
  calcOperatingHours,
  convertRawTime,
  createMarkup,
  formatPhoneNumber,
  getAboutUs,
  getAddress,
  getBingParams,
  getBullets,
  getCategoryIds,
  getGeoFromLocalStorage,
  getGoogleParams,
  getLocationFromZip,
  getPartnerCampaignId,
  getProfileUrl,
  getQS,
  getSessionId,
  getSpecialDiscount,
  hasDetails,
  hasServices,
  hasTags,
  isValidState,
  isWebpSupported,
  parseXml,
  prettifyCity,
  renderServices,
  setCategoryData,
  updateLocalStorage,
}
