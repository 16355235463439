export default {
  'birmingham-al-metro': 'Birmingham, AL',
  'mobile-al-metro': 'Mobile, AL',
  'anchorage-ak-metro': 'Anchorage, AK',
  'phoenix-az-metro': 'Phoenix, AZ',
  'tucson-az-metro': 'Tucson, AZ',
  'littlerock-ar-metro': 'Little Rock, AR',
  'fresno-ca-metro': 'Fresno, CA',
  'losangeles-ca-metro': 'Los Angeles, CA',
  'orangecounty-ca-metro': 'Orange County, CA',
  'riverside-ca-metro': 'Riverside, CA',
  'sacramento-ca-metro': 'Sacramento, CA',
  'sandiego-ca-metro': 'San Diego, CA',
  'sanfrancisco-ca-metro': 'San Francisco, CA',
  'santabarbara-ca-metro': 'Santa Barbara, CA',
  'santacruz-ca-metro': 'Santa Cruz, CA',
  'coloradosprings-co-metro': 'Colorado Springs, CO',
  'denver-co-metro': 'Denver, CO',
  'hartford-ct-metro': 'Hartford, CT',
  'fortlauderdale-fl-metro': 'Fort Lauderdale, FL',
  'jacksonville-fl-metro': 'Jacksonville, FL',
  'keywest-fl-metro': 'Key West, FL',
  'miami-fl-metro': 'Miami, FL',
  'orlando-fl-metro': 'Orlando, FL',
  'westpalmbeach-fl-metro': 'West Palm Beach, FL',
  'atlanta-ga-metro': 'Atlanta, GA',
  'columbusga-ga-metro': 'Columbus, GA',
  'savannah-ga-metro': 'Savannah, GA',
  'honolulu-hi-metro': 'Honolulu, HI',
  'bloomington-il-metro': 'Bloomington, IL',
  'champaign-il-metro': 'Champaign, IL',
  'chicago-il-metro': 'Chicago, IL',
  'indianapolis-in-metro': 'Indianapolis, IN',
  'desmoines-ia-metro': 'Des Moines, IA',
  'wichita-ks-metro': 'Wichita, KS',
  'lexington-ky-metro': 'Lexington, KY',
  'louisville-ky-metro': 'Louisville, KY',
  'batonrouge-la-metro': 'Baton Rouge, LA',
  'lafayette-la-metro': 'Lafayette, LA',
  'neworleans-la-metro': 'New Orleans, LA',
  'portland-or-metro': 'Portland, OR',
  'baltimore-md-metro': 'Baltimore, MD',
  'boston-ma-metro': 'Boston, MA',
  'worcester-ma-metro': 'Worcester, MA',
  'detroit-mi-metro': 'Detroit, MI',
  'flint-mi-metro': 'Flint, MI',
  'grandrapids-mi-metro': 'Grand Rapids, MI',
  'duluth-mn-metro': 'Duluth, MN',
  'kansascity-mo-metro': 'Kansas City, MO',
  'lincoln-ne-metro': 'Lincoln, NE',
  'lasvegas-nv-metro': 'Las Vegas, NV',
  'reno-nv-metro': 'Reno, NV',
  'atlanticcity-nj-metro': 'Atlantic City, NJ',
  'albuquerque-nm-metro': 'Albuquerque, NM',
  'albany-ny-metro': 'Albany, NY',
  'brooklyn-ny-metro': 'Brooklyn, NY',
  'buffalo-ny-metro': 'Buffalo, NY',
  'rochester-ny-metro': 'Rochester, NY',
  'charlotte-nc-metro': 'Charlotte, NC',
  'durham-nc-metro': 'Durham, NC',
  'fayetteville-nc-metro': 'Fayetteville, NC',
  'raleigh-nc-metro': 'Raleigh, NC',
  'cincinnati-oh-metro': 'Cincinnati, OH',
  'cleveland-oh-metro': 'Cleveland, OH',
  'columbus-oh-metro': 'Columbus, OH',
  'dayton-oh-metro': 'Dayton, OH',
  'toledo-oh-metro': 'Toledo, OH',
  'oklahomacity-ok-metro': 'Oklahoma City, OK',
  'tulsa-ok-metro': 'Tulsa, OK',
  'portland-or-metro': 'Portland, OR',
  'harrisburg-pa-metro': 'Harrisburg, PA',
  'philadelphia-pa-metro': 'Philadelphia, PA',
  'pittsburgh-pa-metro': 'Pittsburgh, PA',
  'providence-ri-metro': 'Providence, RI',
  'charleston-sc-metro': 'Charleston, SC',
  'columbia-sc-metro': 'Columbia, SC',
  'chattanooga-tn-metro': 'Chattanooga, TN',
  'knoxville-tn-metro': 'Knoxville, TN',
  'nashville-tn-metro': 'Nashville, TN',
  'memphis-tn-metro': 'Memphis, TN',
  'austin-tx-metro': 'Austin, TX',
  'dallas-tx-metro': 'Dallas, TX',
  'elpaso-tx-metro': 'Elpaso, TX',
  'houston-tx-metro': 'Houston, TX',
  'sanantonio-tx-metro': 'Sanantonio, TX',
  'richmond-va-metro': 'Richmond, VA',
  'virginiabeach-va-metro': 'Virginia Beach, VA',
  'seattle-wa-metro': 'Seattle, WA',
  'spokane-wa-metro': 'Spokane, WA',
  'charleston-sc-metro': 'Charleston, SC',
  'greenbay-wi-metro': 'Green Bay, WI',
  'milwaukee-wi-metro': 'Milwaukee, WI',
  'washingtondc--metro': 'Washington, DC',
}
