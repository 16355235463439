import React from 'react'

import {
  getGeoFromLocalStorage,
  updateLocalStorage,
} from 'helpers'

import city_mapping from './city-mapping'
import subdomain_mapping from './subdomain-mapping'

export const SiteContext = React.createContext()

export const SiteContextProvider = props => {
  const [ state, setState ] = React.useState({
    isLoadingSiteData: true,
    city: '',
    region: '',
    phone: '',
    location: '',
    upsellCategory: new URLSearchParams(window.location.search).get('term') || 'restaurants',
    geoImages: {},
    topBusinesses: {},
    popular_cities: [],
    categories: [
      'HVAC',
      'Plumbers',
      'Electricians',
      'Self-Storage',
      'Automotive Repair',
      'General Contractors',
      'Dentists',
      'Veterinarians',
      'Appliance Repair',
      'Restaurants',
      'Hotels',
      'Spa & Beauty',
      'Movie Theaters',
      'Night Life',
      'Shopping',
    ],
  })

  const metadataSrc = window.__path ? `/${window.__path}/metadata.json` : '/metadata.json'

  // Check for subdomain and attempt to inject the location.
  try {
    const subdomain = window.location.hostname.split('.')[0]
    localStorage.setItem('geoInfo', JSON.stringify({
      location: subdomain_mapping[subdomain],
      city: subdomain_mapping[subdomain].split(', ')[0],
      region_code: subdomain_mapping[subdomain].split(', ')[1],
    }))

  } catch (e) {}
  // Check if the pathname is /guide/* and attempt to inject the location.
  if(window.location.pathname.indexOf('/guide/') > -1){
    try {
      // Get the mapped location.
      const location = window.location.pathname.split('/')[2]
      localStorage.setItem('geoInfo', JSON.stringify({
        location: city_mapping[location],
        city: city_mapping[location].split(', ')[0],
        region_code: city_mapping[location].split(', ')[1],
      }))
    } catch (e) {
      // Something went wrong... Just send the user back to the landing page clean.
      window.location.href = '/'
    }
  }
  const geoInfo = getGeoFromLocalStorage()

  updateLocalStorage()

  const getUpsellCategory = data => {
    // Select a random index of the possible options.  This is a plain object hat we need to temporarily treat like an array.
    const length = Object.entries(data).length
    const index = Math.floor(Math.random() * Object.entries(data).length)
    return Object.entries(data)[index][0]
  }

  const cleanTopBusinesses = (raw = []) => {
    return raw.filter( item => item.results.locations.length > 0 )
      .reduce( (obj, item) => {
        let term = new URLSearchParams(item.results.uri.split('?')[1]).get('what')
        // Need to 'hack' spa...
        if(term.trim() === 'spa'){
          term = 'spa & beauty'
        }
        obj[term] = item
        return obj
    }, {})
  }

  React.useEffect( () => {
    const updateContext = (metadata, geoInfo) => {
      // Finally, update the context object.
      setState( prevState => ({
        ...prevState,
        ...metadata,
        rialto: {
          ...metadata.rialto,
          publisher: metadata.rialto.publisher,
          placement: metadata.rialto.placement,
        },
        ...geoInfo,
        term: new URLSearchParams(window.location.search).get('term') || localStorage.getItem('term') || '',
      }))
      setTimeout( () => {
        setState( prevState => ({
          ...prevState,
          isLoadingSiteData: false,
        }))
      }, 500)
    }

    const fetchTopBusinesses = (metadata, geoInfo) => {
      updateContext(metadata, geoInfo)

      // if(new URLSearchParams(window.location.search).get('useSampleData')) {
      //   metadata.topBusinesses = cleanTopBusinesses(topBusinesses)
      //   updateContext(metadata, geoInfo)
      // } else {
      //   if(state.location === geoInfo.location){
      //     const urls = Object.entries(metadata.discover).map( key => `https://api.citygridmedia.com/content/places/v2/search/where?what=${encodeURIComponent(key[0])}&where=${encodeURIComponent(geoInfo.location)}&publisher=${metadata.rialto.publisher}&format=json&page=1&rpp=4` )
      //     Promise.all(urls.map( url => fetch(url) ))
      //     .then( responses => Promise.all(responses.map( response => response.json() )))
      //     .then( topBusinesses => {
      //       metadata.topBusinesses = cleanTopBusinesses(topBusinesses)
      //       updateContext(metadata, geoInfo)
      //     }).catch( err => {
      //       console.log('error retrieving listings - promises', err)
      //       updateContext(metadata, geoInfo)
      //     })
      //   } else {
      //     updateContext(metadata, geoInfo)
      //   }
      // }
    }

    const fetchGeoinfo = metadata => {
      fetch('//ipwhois.pro/?key=J7OPppp50QNcRxNv')
        .then(response => response.json())
        .then(geoAPIData => {
          if(geoAPIData) {
            const location = geoAPIData.city && geoAPIData.region_code ? `${geoAPIData.city}, ${geoAPIData.region_code}` : geoAPIData.city || ''
            localStorage.setItem('geoInfo', JSON.stringify({
              location: location,
              city: geoAPIData.city,
              region_code: geoAPIData.region_code,
              zip_code: geoAPIData.postal,
            }))
            fetchTopBusinesses(metadata, {
              ...geoAPIData,
            location: location,
          })
          } else {
            fetchTopBusinesses(metadata, {})
          }
        })
        .catch( err => {
          fetchTopBusinesses(metadata, {})
        })
    }

    fetch(metadataSrc)
      .then(response => response.json())
      .then(metadata => {
        // Update metadata from qs.
        const partner_campign_id_override = new URLSearchParams(window.location.search).get('partner_campaign_id')
        metadata.partner_campaign_ids = partner_campign_id_override ? [ partner_campign_id_override.toString() ] : metadata.partner_campaign_ids

        metadata.rialto = metadata.rialto || {}
        metadata.rialto.publisher = new URLSearchParams(window.location.search).get('publisher') || metadata.rialto.publisher
        metadata.rialto.placement = new URLSearchParams(window.location.search).get('placement') || metadata.rialto.placement

        // Set upsellCategory.
        metadata.upsellCategory = getUpsellCategory(metadata.discover)

        if(geoInfo) {
          fetchTopBusinesses(metadata, geoInfo)
        } else {
          fetchGeoinfo(metadata)
        }
      })
      .catch( err => {
        console.log('metadata fetch failed')
        // _Could_ redirect to a 500 page...
      })
  }, [state.location])

  return (
    <SiteContext.Provider value={[ state, setState ]}>
      {props.children}
    </SiteContext.Provider>
  )
}
