export default {
  'birmingham': 'Birmingham, AL',
  'mobile': 'Mobile, AL',
  'anchorage': 'Anchorage, AK',
  'phoenix': 'Phoenix, AZ',
  'tucson': 'Tucson, AZ',
  'littlerock': 'Little Rock, AR',
  'fresno': 'Fresno, CA',
  'losangeles': 'Los Angeles, CA',
  'orangecounty': 'Orange County, CA',
  'riverside': 'Riverside, CA',
  'sacramento': 'Sacramento, CA',
  'sandiego': 'San Diego, CA',
  'sanfrancisco': 'San Francisco, CA',
  'santabarbara': 'Santa Barbara, CA',
  'santacruz': 'Santa Cruz, CA',
  'coloradosprings': 'Colorado Springs, CO',
  'denver': 'Denver, CO',
  'hartford': 'Hartford, CT',
  'fortlauderdale': 'Fort Lauderdale, FL',
  'jacksonville': 'Jacksonville, FL',
  'keywest': 'Key West, FL',
  'miami': 'Miami, FL',
  'orlando': 'Orlando, FL',
  'westpalmbeach': 'West Palm Beach, FL',
  'atlanta': 'Atlanta, GA',
  'columbusga': 'Columbus, GA',
  'savannah': 'Savannah, GA',
  'honolulu': 'Honolulu, HI',
  'bloomington': 'Bloomington, IL',
  'champaign': 'Champaign, IL',
  'chicago': 'Chicago, IL',
  'indianapolis': 'Indianapolis, IN',
  'desmoines': 'Des Moines, IA',
  'wichita': 'Wichita, KS',
  'lexington': 'Lexington, KY',
  'louisville': 'Louisville, KY',
  'batonrouge': 'Baton Rouge, LA',
  'lafayette': 'Lafayette, LA',
  'neworleans': 'New Orleans, LA',
  'portland': 'Portland, OR',
  'baltimore': 'Baltimore, MD',
  'boston': 'Boston, MA',
  'worcester': 'Worcester, MA',
  'detroit': 'Detroit, MI',
  'flint': 'Flint, MI',
  'grandrapids': 'Grand Rapids, MI',
  'duluth': 'Duluth, MN',
  'kansascity': 'Kansas City, MO',
  'lincoln': 'Lincoln, NE',
  'lasvegas': 'Las Vegas, NV',
  'reno': 'Reno, NV',
  'atlanticcity': 'Atlantic City, NJ',
  'albuquerque': 'Albuquerque, NM',
  'albany': 'Albany, NY',
  'brooklyn': 'Brooklyn, NY',
  'buffalo': 'Buffalo, NY',
  'rochester': 'Rochester, NY',
  'charlotte': 'Charlotte, NC',
  'durham': 'Durham, NC',
  'fayetteville': 'Fayetteville, NC',
  'raleigh': 'Raleigh, NC',
  'cincinnati': 'Cincinnati, OH',
  'cleveland': 'Cleveland, OH',
  'columbus': 'Columbus, OH',
  'dayton': 'Dayton, OH',
  'toledo': 'Toledo, OH',
  'oklahomacity': 'Oklahoma City, OK',
  'tulsa': 'Tulsa, OK',
  'portland': 'Portland, OR',
  'harrisburg': 'Harrisburg, PA',
  'philadelphia': 'Philadelphia, PA',
  'pittsburgh': 'Pittsburgh, PA',
  'providence': 'Providence, RI',
  'charleston': 'Charleston, SC',
  'columbia': 'Columbia, SC',
  'chattanooga': 'Chattanooga, TN',
  'knoxville': 'Knoxville, TN',
  'nashville': 'Nashville, TN',
  'memphis': 'Memphis, TN',
  'austin': 'Austin, TX',
  'dallas': 'Dallas, TX',
  'elpaso': 'Elpaso, TX',
  'houston': 'Houston, TX',
  'sanantonio': 'Sanantonio, TX',
  'richmond': 'Richmond, VA',
  'virginiabeach': 'Virginia Beach, VA',
  'seattle': 'Seattle, WA',
  'spokane': 'Spokane, WA',
  'charleston': 'Charleston, SC',
  'greenbay': 'Green Bay, WI',
  'milwaukee': 'Milwaukee, WI',
  'washington': 'Washington, DC',
}
