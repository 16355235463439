const injectElement = ( type, attributes = [], parent ) => {
  if(!type) return

  const ele = document.createElement(type)
  attributes.map( attribute => {
    Object.keys(attribute).map( key => {
      ele.setAttribute(key, attribute[key])
    })
  })
  document.getElementsByTagName('head')[0].appendChild(ele)
}

const removeNodes = async (target = '') => {
  const elements = document.querySelectorAll(target) // e.g. 'meta[property]'
  for (const ele of elements) {
    ele.remove()
  }
}

const restoreViewportMeta = () => {
  removeNodes('link[name="viewport"]')
  injectElement( 'link', [
    { name: 'viewport' },
    { content: 'width=device-width, initial-scale=1' },
  ], document.getElementsByTagName('head')[0])
}

const updateCanonical = (href = '') => {
  removeNodes('link[rel="canonical"]')
  injectElement( 'link', [
    { rel: 'canonical' },
    { href: `${window.location.origin}${window.location.pathname}` },
  ], document.getElementsByTagName('head')[0])
}

const updateMeta = (data = {}, map = {}, metakey = "name" ) => {
  removeNodes(`meta[${metakey}]`)
  restoreViewportMeta()

  for(const key in data) {
    // Populate the template...
    let str = data[key]
    for(const mapkey in map) {
      str = str.replaceAll(`{{${mapkey}}}`, map[mapkey])
    }
    // Clean up any stragglers.
    str = str.replace(/{{.*?}}/g, '')
    if(str.length > 0){
      injectElement( 'meta', [
        { [metakey]: key },
        { content: str },
      ], document.getElementsByTagName('head')[0])
    }
  }
}

export {
  injectElement,
  updateCanonical,
  updateMeta,
}
