import React from 'react'
import { withRouter } from 'react-router-dom'

export class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location) {
      try {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        })
      } catch (e) {
        if (e instanceof TypeError) {
          window.scroll(0, 0)
        } else {}
      }
    }
  }
  render () {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
